import {yupResolver} from '@hookform/resolvers/yup';
import {Alert, Button, Grid, Link, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {isLoaRequiredMcc, TransferIntent} from '@ozark/common';
import {Checkbox, SectionTitle} from '@ozark/common/components';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import SignaturePad from 'react-signature-canvas';
import replace from 'react-string-replace';
import * as yup from 'yup';
import {getSteps} from '../../constants/steps';
import {useStore} from '../../store';
import Title from '../Title';
import {PageSummary} from './Summary';
import {useSummary} from './Summary/hooks';

const getCanvasProps = (
  canvas: HTMLCanvasElement
): {height: number; width: number; pixels: number} => {
  const result = {
    height: canvas.height,
    width: canvas.width,
    pixels: 0,
  };

  const canvasContext = canvas.getContext('2d');

  if (!canvasContext) {
    return result;
  }

  const imgData = canvasContext.getImageData(0, 0, canvas.width, canvas.height);

  for (let i = 0; i < imgData.data.length; i += 4) {
    if (imgData.data[i + 3] > 0) {
      result.pixels++;
    }
  }

  return result;
};

const useStyles = makeStyles(theme => ({
  signatureContainer: {
    border: 'solid 1px rgba(0, 0, 0, 0.87)',
    borderRadius: 4,
    width: '50%',
    height: 150,
    backgroundColor: '#ffffff',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  signaturePad: {
    width: '100%',
    height: '100%',
  },
  terms: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    height: 300,
    overflowY: 'scroll',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

interface Props {
  setValidationHandler(handleSubmit: any): any;
}

type ReviewForm = {
  agree: boolean;
  signature?: string;
  loaAgree?: boolean;
  loaAgreeRequired?: boolean;
};

const schema = yup.object().shape({
  agree: yup
    .boolean()
    .test('agree', 'You must agree to the Terms and Conditions.', (agree: boolean | undefined) => {
      return !!agree;
    }),
  loaAgree: yup
    .boolean()
    .when('loaAgreeRequired', (loaAgreeRequired: boolean | undefined, schema: any) => {
      if (loaAgreeRequired === true) {
        return schema
          .required('You must agree to the LOA')
          .oneOf([true], 'You must agree to the LOA');
      }
    }),
  signature: yup.string().nullable().required('Signature is required'),
});

const ReviewPage = ({setValidationHandler}: Props) => {
  const {authUser, application, group} = useStore();
  const isLoaAgreeRequired = useMemo(() => {
    return isLoaRequiredMcc(application.data?.mcc);
  }, [application.data?.mcc]);
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<ReviewForm>({
    defaultValues: {
      agree: application.data?.agree,
      signature: application.data?.signature,
      loaAgree: application.data?.loaAgree,
      loaAgreeRequired: false,
    },
    resolver: yupResolver(schema),
  });
  const {getFlowAnswers} = useSummary();

  useEffect(() => {
    setValue('loaAgreeRequired', isLoaAgreeRequired);
  }, [isLoaAgreeRequired, setValue]);

  const signatureRef = useRef<SignaturePad>() as React.MutableRefObject<any>;

  const beforeSubmit = useCallback(() => {
    if (!signatureRef || signatureRef.current.isEmpty()) {
      setValue('signature', undefined);
      return;
    }

    const canvasProps = getCanvasProps(signatureRef.current.getTrimmedCanvas());
    const fillRatio = canvasProps.pixels / (canvasProps.height * canvasProps.width);

    // there are 2 places with same logic
    if (canvasProps.height < 50 || canvasProps.width < 100 || fillRatio < 0.01) {
      setValue('signature', undefined);
      return;
    }

    const dataUrl = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    setValue('signature', dataUrl);
  }, [signatureRef, setValue]);

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(() => (onSuccess: any, onError: any) => {
      beforeSubmit();
      return _handleSubmit(onSuccess, onError);
    });
  }, [setValidationHandler, handleSubmit, beforeSubmit]);

  const classes = useStyles();
  const flowAnswers = getFlowAnswers();
  const steps = useMemo(() => getSteps(false), []);
  const stepArray = Object.values(steps);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title h1="Review" h2="You're almost done." />
      </Grid>
      <Grid item xs={12}>
        <PageSummary
          pageAnswers={flowAnswers.basicStepAnswers}
          prevRoute={stepArray[0].next as string}
          anchorId="basicStepAnswers"
          step={stepArray[1]}
        />
      </Grid>
      <Grid item xs={12}>
        <PageSummary
          pageAnswers={flowAnswers.yourBusinessStepAnswers}
          prevRoute={stepArray[1].next as string}
          anchorId="yourBusinessStepAnswers"
          step={stepArray[2]}
        />
      </Grid>
      <Grid item xs={12}>
        <PageSummary
          pageAnswers={flowAnswers.signerInfoStepAnswers}
          prevRoute={stepArray[2].next as string}
          anchorId="signerInfoStepAnswers"
          step={stepArray[3]}
        />
      </Grid>
      <Grid item xs={12}>
        <PageSummary
          pageAnswers={flowAnswers.depositInfoStepAnswers}
          prevRoute={stepArray[3].next as string}
          anchorId="depositInfoStepAnswers"
          step={stepArray[4]}
        />
      </Grid>
      {authUser.claims?.transferIntent !== TransferIntent.transferApplication && (
        <Grid item xs={12}>
          <PageSummary
            pageAnswers={flowAnswers.selectYourProgramStepAnswers}
            prevRoute={stepArray[4].next as string}
            anchorId="selectYourProgramStepAnswers"
            step={stepArray[5]}
          />
        </Grid>
      )}
      {errors && errors.agree && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.agree.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          name="agree"
          control={control}
          render={({field}) => (
            <Checkbox
              {...field}
              aria-label="Agree"
              label={
                <>
                  {replace(
                    'I agree to the Terms and Conditions.',
                    'Terms and Conditions',
                    (match: string) => {
                      return (
                        <Link
                          key={match}
                          href={group.data ? `https://${group.data.termsUrl}` : ''}
                          target="_blank"
                          rel="noopener"
                        >
                          Terms and Conditions
                        </Link>
                      );
                    }
                  )}
                </>
              }
              checked={field.value || false}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.onChange(event.target.checked)
              }
              required
              register={register}
              errors={errors}
            />
          )}
        />
      </Grid>
      {isLoaAgreeRequired && errors && errors.loaAgree && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.loaAgree.message}</Alert>
        </Grid>
      )}
      {isLoaAgreeRequired && (
        <Grid item xs={12}>
          <Controller
            name="loaAgree"
            control={control}
            render={({field}) => (
              <Checkbox
                {...field}
                aria-label="LOA Agree"
                label={
                  <>
                    I as the owner of this business confirm that we will not sell any CBD, THC,
                    Kratom, Delta 8, 9, & 10, or any other prohibited items through this merchant
                    account or via credit card. By completing this application, I’m attesting that
                    this business follows and strictly adheres to all applicable Federal, State, and
                    Local laws that govern the business as well as any associated Card Brand Rules
                    or Regulations. In addition to standard merchant account documentation, I am
                    aware that a copy of all licenses, certifications, etc. related to the business
                    activity may be requested as a condition of approval
                  </>
                }
                sx={{
                  '& .MuiCheckbox-root': {
                    alignSelf: 'start',
                    paddingTop: 0,
                  },
                }}
                checked={field.value || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(event.target.checked)
                }
                required
                register={register}
                errors={errors}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <SectionTitle text="Electronic Signature" />
      </Grid>
      {errors && errors.signature && (
        <Grid item xs={12}>
          <Alert severity="error">{errors.signature.message}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="caption" gutterBottom>
          Draw your signature with your mouse or finger:
        </Typography>
        <div className={classes.signatureContainer}>
          <SignaturePad canvasProps={{className: classes.signaturePad}} ref={signatureRef} />
        </div>
        <Typography variant="caption" gutterBottom>
          <strong>
            Please use the entire signature field to capture a better quality signature
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" onClick={() => signatureRef.current.clear()}>
          Clear Signature
        </Button>
      </Grid>
    </Grid>
  );
};

export default ReviewPage;
