import {yupResolver} from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import {
  AgentView,
  Application,
  ApplicationEquipment,
  ApplicationTemplate,
  ApplicationTemplateView,
  ApplicationType,
  ApplicationTypeAvailableOptions,
  Collections,
  DiscountDuration,
  EquipmentViewableBy,
  Firebase,
  getErrorMessage,
  GroupRole,
  hasOzarkRole,
  isPropsRequired,
  PageFields,
  PCIFee,
  PCIFeeDefaultValues,
  PCIFeeTypes,
  ProcessingTypePayments,
  ProcessingTypes,
  selectApplicationTemplateView,
  ShippingType,
  StatementType,
  UniversalSnapshot,
  useApplicationTemplates,
  useGetAuthorizedAgents,
  useNotification,
  useRatePrograms,
  UserRoles,
  useSimpleBusinessTypes,
} from '@ozark/common';
import {ErrorBoundary, RadioGroup, SectionTitle, Select, TextField} from '@ozark/common/components';
import {EquipmentDescriptionDialog} from '@ozark/common/components/Application/SectionEquipment/EquipmentDescriptionDialog';
import {PRICING_VISA_MASTER} from '@ozark/common/constants/strings';
import {getAdjustedDiscountRateBilledToMerchant, resetProgramFields} from '@ozark/common/helpers';
import {Fragment, useCallback, useEffect, useState} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {useEquipment} from '../../firebase/hooks/useEquipment';
import {useStore} from '../../store';
import {useApplicationQuery} from '../Application/hooks/useApplicationQuery';
import Loading from '../Loading';
import Title from '../Title';
import {UploadDocuments} from '../UploadDocuments';
import {LoadTemplateDialog} from './LoadTemplateDialog';
import {ProgramEquipment} from './ProgramEquipment';
import {getProgramEquipmentSchema} from './programEquipmentSchema';
import {SaveTemplateDialog} from './SaveTemplateDialog';

interface Props {
  setValidationHandler(handleSubmit: any): any;
  setEndSessionHandler?(handleEndSession: any): any;
  setOnSaveTemplateClickHandler?(handleOnSaveTemplateClick: any): any;
  loadedApplicationTemplate?: ApplicationTemplateView;
  setLoadedApplicationTemplate?: (applicationTemplate?: ApplicationTemplateView) => void;
}

const agentUnassignedValue = 'null';

const scrollLastEquipmentIntoView = () => {
  setTimeout(() => {
    const equipmentAdditionalElements = document.querySelectorAll('.equipment-item');
    if (equipmentAdditionalElements && equipmentAdditionalElements.length) {
      const elementLast = equipmentAdditionalElements[equipmentAdditionalElements.length - 1];
      elementLast.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, 300);
};

const transform = (onSuccess: any, data: any, authorizedAgents: AgentView[]) => {
  if (
    [ApplicationType.flatRate, ApplicationType.interchange].includes(data.rateSet.applicationType)
  ) {
    data.rateSet.midQualifiedDiscountRateVisaMastercardDiscover =
      data.rateSet.qualifiedDiscountRateVisaMastercardDiscover;
    data.rateSet.nonQualifiedDiscountRateVisaMastercardDiscover =
      data.rateSet.qualifiedDiscountRateVisaMastercardDiscover;
    data.rateSet.midQualifiedTransactionFeeVisaMastercardDiscover =
      data.rateSet.qualifiedTransactionFeeVisaMastercardDiscover;
    data.rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover =
      data.rateSet.qualifiedTransactionFeeVisaMastercardDiscover;
    data.rateSet.midQualifiedDiscountRateAmex = data.rateSet.qualifiedDiscountRateAmex;
    data.rateSet.nonQualifiedDiscountRateAmex = data.rateSet.qualifiedDiscountRateAmex;
    data.rateSet.midQualifiedTransactionFeeAmex = data.rateSet.qualifiedTransactionFeeAmex;
    data.rateSet.nonQualifiedTransactionFeeAmex = data.rateSet.qualifiedTransactionFeeAmex;
  }
  if (
    [
      ApplicationType.cashDiscount,
      ApplicationType.surcharge,
      ApplicationType.dualPricingPassThroughFees,
      ApplicationType.dualPricingFlatRate,
    ].includes(data.rateSet.applicationType)
  ) {
    data.rateSet.midQualifiedDiscountRateVisaMastercardDiscover =
      data.rateSet.qualifiedDiscountRateVisaMastercardDiscover;
    data.rateSet.nonQualifiedDiscountRateVisaMastercardDiscover =
      data.rateSet.qualifiedDiscountRateVisaMastercardDiscover;
    data.rateSet.midQualifiedTransactionFeeVisaMastercardDiscover =
      data.rateSet.qualifiedTransactionFeeVisaMastercardDiscover;
    data.rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover =
      data.rateSet.qualifiedTransactionFeeVisaMastercardDiscover;
    data.rateSet.midQualifiedDiscountRateAmex =
      data.rateSet.qualifiedDiscountRateVisaMastercardDiscover;
    data.rateSet.nonQualifiedDiscountRateAmex =
      data.rateSet.qualifiedDiscountRateVisaMastercardDiscover;
    data.rateSet.midQualifiedTransactionFeeAmex =
      data.rateSet.qualifiedTransactionFeeVisaMastercardDiscover;
    data.rateSet.nonQualifiedTransactionFeeAmex =
      data.rateSet.qualifiedTransactionFeeVisaMastercardDiscover;
  }

  if (data.rateSet.applicationType !== ApplicationType.interchange) {
    data.rateSet.statementType = null;
  }

  if (data.rateSet.applicationType === ApplicationType.dualPricingFlatRate) {
    data.rateSet.qualifiedDebitNonPINRate =
      data.rateSet.qualifiedDiscountRateVisaMastercardDiscover;

    data.rateSet.midQualifiedDiscountRateVisaMastercardDiscover = 0;
    data.rateSet.nonQualifiedDiscountRateVisaMastercardDiscover = 0;
    data.rateSet.midQualifiedTransactionFeeVisaMastercardDiscover = 0;
    data.rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover = 0;
    data.rateSet.midQualifiedDiscountRateAmex = 0;
    data.rateSet.nonQualifiedDiscountRateAmex = 0;
  }

  data.shipping.other = data.shipping.select === ShippingType.other;
  data.shipping.future = data.shipping.select !== ShippingType.other;
  if (data.agentId && data.agentId !== agentUnassignedValue) {
    const agent = authorizedAgents.find(x => x.id === data.agentId);
    if (agent) {
      data.agent = {
        id: agent.id,
        firstName: agent.firstName,
        lastName: agent.lastName,
      };
    }
  } else {
    data.agent = null;
  }
  const prevAgentId = data.agentId;
  delete data.agentId;
  onSuccess(data);
  //we need to preserve agentId if it was set. Otherwise, Agent combobox will reset the value
  data.agentId = prevAgentId;
};

type SaveTemplateDialogOpen = {
  isOpen: boolean;
};

type LoadTemplateDialogOpen = {
  isOpen: boolean;
};

const defaultMailStatement = 'false';

const ProgramPageForAgents = ({
  setValidationHandler,
  setEndSessionHandler,
  setOnSaveTemplateClickHandler,
  loadedApplicationTemplate,
  setLoadedApplicationTemplate,
}: Props) => {
  const showNotification = useNotification();
  const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] = useState<SaveTemplateDialogOpen>({
    isOpen: false,
  });
  const [loadTemplateDialogOpen, setLoadTemplateDialogOpen] = useState<LoadTemplateDialogOpen>({
    isOpen: false,
  });
  const [appTemplateFunctionalityEnabled, setAppTemplateFunctionalityEnabled] =
    useState<boolean>(false);
  const [authorizedAgents, setAuthorizedAgents] = useState<AgentView[]>([]);

  const {
    application,
    authUser: {claims, data: authUserData},
  } = useStore();
  const [currentApplication, setCurrentApplication] = useState<Application>();

  let groupRole = GroupRole.member;
  if (claims && (claims.groupRole === GroupRole.administrator || claims.role !== UserRoles.agent)) {
    groupRole = GroupRole.administrator;
  }
  const getAuthorizedAgents = useGetAuthorizedAgents({
    groupId: application?.data?.group?.id,
    groupRole: groupRole,
    role: UserRoles.agent,
    uid: authUserData?.uid,
  });

  const isCrmUser =
    claims && claims?.role !== UserRoles.agent && claims?.role !== UserRoles.merchant;

  useEffect(() => {
    getAuthorizedAgents().then(agents => {
      const authAgents = agents?.filter(agent => agent.isActive) || [];
      setAuthorizedAgents(authAgents);
      if (!authAgents.length) {
        return;
      }
    });
  }, [application?.data?.group?.id, authUserData, claims, getAuthorizedAgents]);

  const {applicationTemplates} = useApplicationTemplates(authUserData?.uid);

  const {isSimpleBusinessTypesLoaded, getBusinessTypeNames, getMccByBusinessType} =
    useSimpleBusinessTypes({displayMccCodes: true});

  const isErpUser = hasOzarkRole(claims?.role as UserRoles | undefined);

  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(getProgramEquipmentSchema()),
    defaultValues: {
      ...(application.data ?? {}),
      rateSet: {
        otherVolumeRate: 0,
        otherItemFee: 0,
        accountOnFileFee: 10,
        chargebackFee: 25,
        retrievalFee: 25,
        monthlyMinimumFee: 25,
        avsFee: 0.05,
        batchFee: 0,
        debitAccessFee: 0,
        additionalServicesFee: 0,
        earlyDeconversionFee: 0,
        annualFee: 0,
        regulatoryFee: 0,
        mailStatement: defaultMailStatement,
        ...application?.data?.rateSet,
      },
    },
  });
  const {isSummary} = useApplicationQuery();

  const watchProcessingType = watch('processingType');

  const {equipment} = useEquipment(
    watchProcessingType || ProcessingTypes.cardPresent,
    isErpUser ? EquipmentViewableBy.erpUser : EquipmentViewableBy.agent
  );

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) =>
        _handleSubmit((data: any) => {
          // ERP and Portal Agents transform, because of displayMccCodesOn this page
          const rawBusinessType = data.businessType;
          const escapedBusinessType = rawBusinessType.replace(/^\d+ - /, '');

          data.mcc =
            getMccByBusinessType(rawBusinessType) ??
            getMccByBusinessType(escapedBusinessType) ??
            '';
          data.businessType = escapedBusinessType;

          transform(onSuccess, data, authorizedAgents);
        }, onError)
    );
  }, [setValidationHandler, handleSubmit, authorizedAgents, getMccByBusinessType]);

  useEffect(() => {
    if (!setEndSessionHandler) return;
    setEndSessionHandler(() => () => {
      return getValues();
    });
    // eslint-disable-next-line
  }, [setEndSessionHandler]);

  useEffect(() => {
    if (!setOnSaveTemplateClickHandler) {
      setAppTemplateFunctionalityEnabled(false);
      return;
    }
    setOnSaveTemplateClickHandler(() => () => {
      setSaveTemplateDialogOpen({isOpen: true});
    });
    setAppTemplateFunctionalityEnabled(true);
    return () => {
      setOnSaveTemplateClickHandler(undefined);
    };
    // eslint-disable-next-line
  }, [setOnSaveTemplateClickHandler]);

  useEffect(() => {
    register('equipment.id');
    register('shipping.select');
    register('shipping.other');
    register('equipment.isGateway');
    register('equipment.isOther');
    register('equipment.isVarSheet');

    // there is no special condition for registering additional field due to no yup additionalFields-prop validation
    // we need this to avoid unwanted register/unregister form behavior when values became undefined
    register('equipment.additionalFields');

    if (!application.data?.equipment?.name) register('equipment.name');
    if (!application.data?.equipment?.imageUrl) register('equipment.imageUrl');
    // eslint-disable-next-line
  }, [register]);

  useEffect(() => {
    if (!!application.data?.agent?.id) {
      setValue('agentId', application.data.agent.id);
      return;
    }
    if (claims?.role === UserRoles.agent) {
      setValue('agentId', authUserData?.uid);
      return;
    }

    setValue('agentId', agentUnassignedValue);
  }, [setValue, application?.data?.agent?.id, authUserData?.uid, claims?.role]);

  const watchBusinessType = watch('businessType');
  const watchApplicationType: ApplicationType = watch('rateSet.applicationType');
  const watchQualifiedDiscountRateVisaMastercardDiscover = watch<string>(
    'rateSet.qualifiedDiscountRateVisaMastercardDiscover'
  );
  const watchQualifiedDebitPINRate = watch<string>('rateSet.qualifiedDebitPINRate');
  const watchQualifiedTransactionFeeVisaMastercardDiscover = watch(
    'rateSet.qualifiedTransactionFeeVisaMastercardDiscover'
  );
  const pciFeeTypes = Object.keys(PCIFeeTypes);
  const watchPCIFee = watch('rateSet.pciFee', PCIFee.waived);
  const displayPCIFeeValue = Object.values(PCIFee)
    .filter(x => x !== PCIFee.waived)
    .includes(watchPCIFee as PCIFee);
  const isCnpVCp = watchApplicationType === ApplicationType.cnpVCp;
  const isErr = watchApplicationType === ApplicationType.err;
  const showDebitRates = !isCnpVCp || watchProcessingType === ProcessingTypes.cardPresent;

  const isDebit = ![
    ApplicationType.interchange,
    ApplicationType.err,
    ApplicationType.flatRate,
    ApplicationType.tiered,
  ].includes(watchApplicationType);

  const autoFillMidAndNonQual =
    watchApplicationType &&
    [
      ApplicationType.surcharge,
      ApplicationType.dualPricingPassThroughFees,
      ApplicationType.dualPricingFlatRate,
      ApplicationType.cashDiscount,
      ApplicationType.flatRate,
      ApplicationType.interchange,
    ].includes(watchApplicationType);

  const handlePCIFeeChange = (value: string) => {
    setValue('rateSet.pciFeeValue', PCIFeeDefaultValues[value as PCIFee]);
  };

  useEffect(() => {
    resetProgramFields(setValue, watchApplicationType, watchProcessingType);
  }, [watchApplicationType, watchProcessingType, setValue]);

  useEffect(() => {
    if (
      watchApplicationType !== ApplicationType.cashDiscount &&
      watchApplicationType !== ApplicationType.surcharge &&
      watchApplicationType !== ApplicationType.dualPricingPassThroughFees &&
      watchApplicationType !== ApplicationType.dualPricingFlatRate
    )
      return;
    setValue('rateSet.qualifiedDiscountRateAmex', watchQualifiedDiscountRateVisaMastercardDiscover);
    setValue(
      'rateSet.qualifiedTransactionFeeAmex',
      watchQualifiedTransactionFeeVisaMastercardDiscover
    );
  }, [
    setValue,
    watchApplicationType,
    watchQualifiedDiscountRateVisaMastercardDiscover,
    watchQualifiedTransactionFeeVisaMastercardDiscover,
  ]);

  const displayProcessingType = (processingType: ProcessingTypes) => {
    const paymentInfo = ProcessingTypePayments[processingType];

    return (
      <FormControlLabel
        key={processingType}
        value={processingType}
        control={<Radio disabled={isSummary} />}
        label={
          <Typography>
            {paymentInfo.title} &nbsp;
            <Typography variant="caption">&bull; {paymentInfo.caption}</Typography>
          </Typography>
        }
      />
    );
  };

  const onSaveTemplate = useCallback(
    async (existingTemplate?: ApplicationTemplateView, newTemplateName?: string) => {
      if (!authUserData?.uid) {
        return;
      }
      if (!existingTemplate && !newTemplateName) {
        return;
      }

      try {
        const formData = getValues();

        let equipment = formData.equipment;
        if (!equipment?.name) {
          //if formData has equipment and equipment's name is not set - we have some bad data in the form data
          equipment = undefined;
        }

        const templateData = {
          processingType: formData.processingType,
          rateSet: formData.rateSet,
          equipment: equipment,
        };

        if (existingTemplate) {
          await Firebase.firestore
            .collection(Collections.applicationsTemplates)
            .doc(existingTemplate.id)
            .set(templateData, {merge: true});
        } else {
          const templateName = newTemplateName!;
          if (applicationTemplates.data?.find(x => x.templateName === templateName)) {
            showNotification('error', 'Template with chosen name already exists.');
            return;
          }
          const applicationTemplate: ApplicationTemplate = {
            templateName,
            createdBy: authUserData.uid,
            ...templateData,
          };
          const templateRef = await Firebase.firestore
            .collection(Collections.applicationsTemplates)
            .add(applicationTemplate);
          const template = await templateRef.get();
          if (!!setLoadedApplicationTemplate) {
            setLoadedApplicationTemplate(
              selectApplicationTemplateView(template as UniversalSnapshot<ApplicationTemplate>)
            );
          }
        }
        showNotification('success', 'Template saved successfully');
        setSaveTemplateDialogOpen({isOpen: false});
      } catch (err) {
        console.error(err);
        showNotification('error', 'An error occurred while saving.');
      }
    },
    [
      setLoadedApplicationTemplate,
      applicationTemplates.data,
      authUserData?.uid,
      getValues,
      showNotification,
    ]
  );

  const onLoadTemplate = useCallback(
    async (applicationTemplate: ApplicationTemplateView) => {
      try {
        setValue('processingType', applicationTemplate.processingType, {shouldDirty: true});

        const equipment = applicationTemplate.equipment;
        if (equipment) {
          setValue('equipment', equipment, {shouldDirty: true});
        }

        let rateSet = application.data?.rateSet;
        if (applicationTemplate.rateSet) {
          if (!rateSet) {
            rateSet = {};
          }
          //we need to update only rateSet fields from current page
          rateSet.applicationType = applicationTemplate.rateSet.applicationType;
          rateSet.discountDuration = applicationTemplate.rateSet.discountDuration;
          rateSet.qualifiedDiscountRateVisaMastercardDiscover =
            applicationTemplate.rateSet.qualifiedDiscountRateVisaMastercardDiscover;
          rateSet.qualifiedTransactionFeeVisaMastercardDiscover =
            applicationTemplate.rateSet.qualifiedTransactionFeeVisaMastercardDiscover;
          rateSet.midQualifiedDiscountRateVisaMastercardDiscover =
            applicationTemplate.rateSet.midQualifiedDiscountRateVisaMastercardDiscover;
          rateSet.nonQualifiedDiscountRateVisaMastercardDiscover =
            applicationTemplate.rateSet.nonQualifiedDiscountRateVisaMastercardDiscover;
          rateSet.midQualifiedTransactionFeeVisaMastercardDiscover =
            applicationTemplate.rateSet.midQualifiedTransactionFeeVisaMastercardDiscover;
          rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover =
            applicationTemplate.rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover;
          rateSet.qualifiedDiscountRateAmex = applicationTemplate.rateSet.qualifiedDiscountRateAmex;
          rateSet.midQualifiedDiscountRateAmex =
            applicationTemplate.rateSet.midQualifiedDiscountRateAmex;
          rateSet.nonQualifiedDiscountRateAmex =
            applicationTemplate.rateSet.nonQualifiedDiscountRateAmex;
          rateSet.qualifiedTransactionFeeAmex =
            applicationTemplate.rateSet.qualifiedTransactionFeeAmex;
          rateSet.midQualifiedTransactionFeeAmex =
            applicationTemplate.rateSet.midQualifiedTransactionFeeAmex;
          rateSet.nonQualifiedTransactionFeeAmex =
            applicationTemplate.rateSet.nonQualifiedTransactionFeeAmex;
          rateSet.qualifiedDebitNonPINRate = applicationTemplate.rateSet.qualifiedDebitNonPINRate;
          rateSet.qualifiedDebitNonPINFee = applicationTemplate.rateSet.qualifiedDebitNonPINFee;
          rateSet.qualifiedDebitPINRate = applicationTemplate.rateSet.qualifiedDebitPINRate;
          rateSet.qualifiedDebitPINFee = applicationTemplate.rateSet.qualifiedDebitPINFee;
          rateSet.otherVolumeRate = applicationTemplate.rateSet.otherVolumeRate;
          rateSet.otherItemFee = applicationTemplate.rateSet.otherItemFee;
          rateSet.accountOnFileFee = applicationTemplate.rateSet.accountOnFileFee;
          rateSet.chargebackFee = applicationTemplate.rateSet.chargebackFee;
          rateSet.retrievalFee = applicationTemplate.rateSet.retrievalFee;
          rateSet.monthlyMinimumFee = applicationTemplate.rateSet.monthlyMinimumFee;
          rateSet.avsFee = applicationTemplate.rateSet.avsFee;
          rateSet.batchFee = applicationTemplate.rateSet.batchFee;
          rateSet.debitAccessFee = applicationTemplate.rateSet.debitAccessFee;
          rateSet.additionalServicesFee = applicationTemplate.rateSet.additionalServicesFee;
          rateSet.pciFee = applicationTemplate.rateSet.pciFee;
          rateSet.ebtDiscountRate = applicationTemplate.rateSet.ebtDiscountRate;
          rateSet.ebtTransactionFee = applicationTemplate.rateSet.ebtTransactionFee;
          rateSet.pciFeeValue = applicationTemplate.rateSet.pciFeeValue;
          rateSet.earlyDeconversionFee = applicationTemplate.rateSet.earlyDeconversionFee;
          rateSet.annualFee = applicationTemplate.rateSet.annualFee;
          rateSet.regulatoryFee = applicationTemplate.rateSet.regulatoryFee;
          rateSet.mailStatement = applicationTemplate.rateSet.mailStatement ?? defaultMailStatement;
          rateSet.statementType = applicationTemplate.rateSet.statementType;
        }
        setValue('rateSet', rateSet, {shouldDirty: true});
        setCurrentApplication({
          ...application.data,
          processingType: applicationTemplate.processingType,
          rateSet: rateSet,
          equipment: equipment ?? application.data.equipment,
        });
        if (!!setLoadedApplicationTemplate) {
          setLoadedApplicationTemplate(applicationTemplate);
        }
        showNotification('success', 'Template pricing was successfully loaded');
        setLoadTemplateDialogOpen({isOpen: false});
      } catch (err) {
        console.error(err);
        showNotification('error', 'An error occurred while loading template.');
      }
    },
    [setLoadedApplicationTemplate, showNotification, setValue, application.data]
  );

  const {ratePrograms} = useRatePrograms();

  const {fields, append, remove} = useFieldArray({
    name: 'equipmentAdditional',
    control,
  });
  const [descriptionDialogOpen, setNewItemDialogOpen] = useState(false);
  const handleEquipmentAddDialogOpen = () => {
    setNewItemDialogOpen(true);
  };

  const handleEquipmentAdd = (description: string) => {
    const equipmentItemNew = {description};
    append(equipmentItemNew);
    setNewItemDialogOpen(false);
    scrollLastEquipmentIntoView();
  };

  const generateAgentOptions = () => [
    <MenuItem value={agentUnassignedValue}>Unassigned</MenuItem>,
    <Divider />,
    authorizedAgents.sortAndMap(
      agent => (
        <MenuItem value={agent.id}>
          {agent.firstName} {agent.lastName}
        </MenuItem>
      ),
      agent => `${agent.firstName} ${agent.lastName}`
    ),
  ];

  if (!equipment.data || !application.data || !isSimpleBusinessTypesLoaded) {
    return <Loading />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Title h1="Pricing & Equipment" h2="Fill out the merchant's pricing and equipment." />
      </Grid>
      {appTemplateFunctionalityEnabled && (
        <Grid item xs={12}>
          <Typography variant="caption" component="span" gutterBottom>
            Selected template:
          </Typography>
          <Typography
            variant="body1"
            component="span"
            gutterBottom
            sx={{marginLeft: '10px', marginRight: '10px'}}
          >
            {loadedApplicationTemplate ? loadedApplicationTemplate.templateName : 'Not Loaded'}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setLoadTemplateDialogOpen({isOpen: true});
            }}
          >
            Select Pricing Template
          </Button>
          <Divider sx={{marginTop: '10px'}} />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          name="doingBusinessAs"
          label="Doing Business As (DBA)"
          errors={errors}
          control={control}
          inputProps={{maxLength: 25}}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="businessType"
          control={control}
          rules={{required: true}}
          defaultValue=""
          render={({field}) => (
            <Autocomplete
              id="businessType"
              sx={{width: '100%'}}
              disableClearable={!watchBusinessType}
              options={getBusinessTypeNames()}
              {...field}
              onChange={(_event, newInputValue) => {
                setValue('businessType', newInputValue);
              }}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  error={Boolean(getErrorMessage(`businessType`, errors))}
                  helperText={getErrorMessage(`businessType`, errors)?.message}
                  fullWidth
                  name="businessType"
                  label="What is your specific business type?"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          name="processingType"
          label="How will the merchant be processing payments?"
          defaultValue=""
          required
          errors={errors}
          control={control}
          disabled={isSummary}
        >
          {Object.keys(ProcessingTypePayments).map(key =>
            displayProcessingType(key as ProcessingTypes)
          )}
        </RadioGroup>
      </Grid>
      <Grid item xs={12}>
        <Select
          name="rateSet.applicationType"
          label="Application Type"
          required
          errors={errors}
          control={control}
          options={ApplicationTypeAvailableOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="rateSet.discountDuration"
          label="Discount Duration"
          required
          defaultValue={DiscountDuration.daily}
          errors={errors}
          control={control}
          options={Object.values(DiscountDuration)}
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          name="rateSet.mailStatement"
          label="Mail Statement"
          required
          errors={errors}
          control={control}
          yesOrNo
        />
      </Grid>
      {watchApplicationType === ApplicationType.interchange && (
        <Grid item xs={12}>
          <Select
            name="rateSet.statementType"
            label="Statement Type"
            defaultValue={StatementType.summary}
            required
            errors={errors}
            control={control}
            options={Object.values(StatementType)}
            compareFn={() => 0}
          />
        </Grid>
      )}
      {(watchApplicationType === ApplicationType.cashDiscount ||
        watchApplicationType === ApplicationType.surcharge ||
        watchApplicationType === ApplicationType.dualPricingPassThroughFees ||
        watchApplicationType === ApplicationType.dualPricingFlatRate) && (
        <Fragment>
          <Grid item xs={12}>
            <SectionTitle text="Visa, Mastercard, Discover and AMEX" />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="rateSet.qualifiedDiscountRateVisaMastercardDiscover"
              label={
                watchApplicationType === ApplicationType.surcharge ||
                watchApplicationType === ApplicationType.dualPricingPassThroughFees ||
                watchApplicationType === ApplicationType.dualPricingFlatRate
                  ? 'Credit/Signature Debit Rate'
                  : 'Credit/Debit Rate'
              }
              required
              type="number"
              errors={errors}
              control={control}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              helperText={
                watchQualifiedDiscountRateVisaMastercardDiscover &&
                `Adjusted Discount Rate Billed to Merchant ${getAdjustedDiscountRateBilledToMerchant(
                  watchQualifiedDiscountRateVisaMastercardDiscover
                )}`
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="rateSet.qualifiedTransactionFeeVisaMastercardDiscover"
              label="Transaction Fee"
              type="number"
              errors={errors}
              control={control}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
        </Fragment>
      )}
      {(watchApplicationType === ApplicationType.surcharge ||
        watchApplicationType === ApplicationType.dualPricingPassThroughFees ||
        watchApplicationType === ApplicationType.dualPricingFlatRate) && (
        <>
          {watchProcessingType === ProcessingTypes.cardPresent && (
            <>
              <Grid item xs={12}>
                <SectionTitle text="Debit" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="rateSet.qualifiedDebitPINRate"
                  label="Debit Pin Based Rate"
                  required
                  type="number"
                  errors={errors}
                  control={control}
                  defaultValue="0"
                  helperText={
                    watchApplicationType === ApplicationType.dualPricingFlatRate &&
                    watchQualifiedDebitPINRate &&
                    `Adjusted Discount Rate Billed to Merchant ${getAdjustedDiscountRateBilledToMerchant(
                      watchQualifiedDebitPINRate
                    )}`
                  }
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="rateSet.qualifiedDebitPINFee"
                  label="Debit Pin Based Transaction Fee"
                  required
                  type="number"
                  errors={errors}
                  control={control}
                  defaultValue="0"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <SectionTitle text="EBT" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="rateSet.ebtDiscountRate"
              label="EBT Discount Rate"
              required
              type="number"
              errors={errors}
              control={control}
              defaultValue="0"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="rateSet.ebtTransactionFee"
              label="EBT Transaction Fee"
              required
              type="number"
              errors={errors}
              control={control}
              defaultValue="0"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
        </>
      )}
      {watchApplicationType !== ApplicationType.cashDiscount &&
        watchApplicationType !== ApplicationType.surcharge &&
        watchApplicationType !== ApplicationType.dualPricingPassThroughFees &&
        watchApplicationType !== ApplicationType.dualPricingFlatRate && (
          <Fragment>
            <Grid item xs={12}>
              <SectionTitle text={`${PRICING_VISA_MASTER}${isDebit ? ' and Debit' : ''}`} />
            </Grid>
            <Grid item xs={12} sm={autoFillMidAndNonQual || isCnpVCp || isErr ? 6 : 4}>
              <TextField
                name="rateSet.qualifiedDiscountRateVisaMastercardDiscover"
                label="Qualified Rate"
                required={isPropsRequired(
                  PageFields.qualifiedDiscountRateVisaMastercardDiscover,
                  watchApplicationType
                )}
                type="number"
                errors={errors}
                control={control}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            {!autoFillMidAndNonQual && (
              <Fragment>
                {!isCnpVCp && !isErr && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="rateSet.midQualifiedDiscountRateVisaMastercardDiscover"
                      label="Mid-Qualified Rate"
                      required={isPropsRequired(
                        PageFields.midQualifiedDiscountRateVisaMastercardDiscover,
                        watchApplicationType
                      )}
                      type="number"
                      errors={errors}
                      control={control}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={isCnpVCp || isErr ? 6 : 4}>
                  <TextField
                    name="rateSet.nonQualifiedDiscountRateVisaMastercardDiscover"
                    label={isErr ? 'Non-Qualified Surcharge' : 'Non-Qualified Rate'}
                    required={isPropsRequired(
                      PageFields.nonQualifiedDiscountRateVisaMastercardDiscover,
                      watchApplicationType
                    )}
                    type="number"
                    errors={errors}
                    control={control}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
              </Fragment>
            )}
            <Grid item xs={12} sm={autoFillMidAndNonQual || isCnpVCp || isErr ? 6 : 4}>
              <TextField
                name="rateSet.qualifiedTransactionFeeVisaMastercardDiscover"
                label="Qualified Transaction Fee"
                required={isPropsRequired(
                  PageFields.qualifiedTransactionFeeVisaMastercardDiscover,
                  watchApplicationType
                )}
                type="number"
                errors={errors}
                control={control}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
            {!autoFillMidAndNonQual && (
              <Fragment>
                {!isCnpVCp && !isErr && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="rateSet.midQualifiedTransactionFeeVisaMastercardDiscover"
                      label="Mid-Qualified Transaction Fee"
                      required={isPropsRequired(
                        PageFields.midQualifiedTransactionFeeVisaMastercardDiscover,
                        watchApplicationType
                      )}
                      type="number"
                      errors={errors}
                      control={control}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}
                {!isErr && (
                  <Grid item xs={12} sm={isCnpVCp ? 6 : 4}>
                    <TextField
                      name="rateSet.nonQualifiedTransactionFeeVisaMastercardDiscover"
                      label="Non-Qualified Transaction Fee"
                      required={isPropsRequired(
                        PageFields.nonQualifiedTransactionFeeVisaMastercardDiscover,
                        watchApplicationType
                      )}
                      type="number"
                      errors={errors}
                      control={control}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}
              </Fragment>
            )}

            <Grid item xs={12}>
              <SectionTitle text="American Express" />
            </Grid>
            <Grid item xs={12} sm={autoFillMidAndNonQual || isCnpVCp || isErr ? 6 : 4}>
              <TextField
                name="rateSet.qualifiedDiscountRateAmex"
                label="Qualified Rate"
                required={isPropsRequired(
                  PageFields.qualifiedDiscountRateAmex,
                  watchApplicationType
                )}
                type="number"
                errors={errors}
                control={control}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            {!autoFillMidAndNonQual && (
              <Fragment>
                {!isCnpVCp && !isErr && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="rateSet.midQualifiedDiscountRateAmex"
                      label="Mid-Qualified Rate"
                      required={isPropsRequired(
                        PageFields.midQualifiedDiscountRateAmex,
                        watchApplicationType
                      )}
                      type="number"
                      errors={errors}
                      control={control}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={isCnpVCp || isErr ? 6 : 4}>
                  <TextField
                    name="rateSet.nonQualifiedDiscountRateAmex"
                    label={isErr ? 'Non-Qualified Surcharge' : 'Non-Qualified Rate'}
                    required={isPropsRequired(
                      PageFields.nonQualifiedDiscountRateAmex,
                      watchApplicationType
                    )}
                    type="number"
                    errors={errors}
                    control={control}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
              </Fragment>
            )}
            <Grid item xs={12} sm={autoFillMidAndNonQual || isCnpVCp || isErr ? 6 : 4}>
              <TextField
                name="rateSet.qualifiedTransactionFeeAmex"
                label="Qualified Transaction Fee"
                required={isPropsRequired(
                  PageFields.qualifiedTransactionFeeAmex,
                  watchApplicationType
                )}
                type="number"
                errors={errors}
                control={control}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
            {!autoFillMidAndNonQual && (
              <Fragment>
                {!isErr && !isCnpVCp && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="rateSet.midQualifiedTransactionFeeAmex"
                      label="Mid-Qualified Transaction Fee"
                      required={isPropsRequired(
                        PageFields.midQualifiedTransactionFeeAmex,
                        watchApplicationType
                      )}
                      type="number"
                      errors={errors}
                      control={control}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}
                {!isErr && (
                  <Grid item xs={12} sm={isCnpVCp ? 6 : 4}>
                    <TextField
                      name="rateSet.nonQualifiedTransactionFeeAmex"
                      label="Non-Qualified Transaction Fee"
                      required={isPropsRequired(
                        PageFields.nonQualifiedTransactionFeeAmex,
                        watchApplicationType
                      )}
                      type="number"
                      errors={errors}
                      control={control}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                )}
              </Fragment>
            )}
            {showDebitRates && (
              <Grid item xs={12}>
                <SectionTitle text="Debit" />
              </Grid>
            )}
            {!isCnpVCp && (
              <Grid item xs={12} sm={6}>
                <TextField
                  name="rateSet.qualifiedDebitNonPINRate"
                  label="Signature Debit Non-Pin Based Rate"
                  required
                  type="number"
                  errors={errors}
                  control={control}
                  defaultValue="0"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
            )}
            {!isCnpVCp && (
              <Grid item xs={12} sm={6}>
                <TextField
                  name="rateSet.qualifiedDebitNonPINFee"
                  label="Signature Debit Non-Pin Based Transaction Fee"
                  required
                  type="number"
                  errors={errors}
                  control={control}
                  defaultValue="0"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            )}

            {watchProcessingType === ProcessingTypes.cardPresent && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="rateSet.qualifiedDebitPINRate"
                    label="Debit Pin Based Rate"
                    required
                    type="number"
                    errors={errors}
                    control={control}
                    defaultValue="0"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="rateSet.qualifiedDebitPINFee"
                    label="Debit Pin Based Transaction Fee"
                    required
                    type="number"
                    errors={errors}
                    control={control}
                    defaultValue="0"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
              </>
            )}
            {showDebitRates && (
              <Grid item xs={12}>
                <SectionTitle text="EBT" />
              </Grid>
            )}
            {showDebitRates && (
              <Grid item xs={12} sm={6}>
                <TextField
                  name="rateSet.ebtDiscountRate"
                  label="EBT Discount Rate"
                  required
                  type="number"
                  errors={errors}
                  control={control}
                  defaultValue="0"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
            )}
            {showDebitRates && (
              <Grid item xs={12} sm={6}>
                <TextField
                  name="rateSet.ebtTransactionFee"
                  label="EBT Transaction Fee"
                  required
                  type="number"
                  errors={errors}
                  control={control}
                  defaultValue="0"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            )}
          </Fragment>
        )}
      <Grid item xs={12}>
        <SectionTitle text="Other Volume & Other Item Fee (All Card Types)" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="rateSet.otherVolumeRate"
          label="Discount Rate"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="rateSet.otherItemFee"
          label="Transaction Fee"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SectionTitle text="Miscellaneous Fees" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="rateSet.accountOnFileFee"
          label="Account on File Fee"
          required
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="rateSet.chargebackFee"
          label="Chargeback Fee"
          required
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="rateSet.retrievalFee"
          label="Retrieval Fee"
          required
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="rateSet.monthlyMinimumFee"
          label="Monthly Minimum Fee"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="rateSet.avsFee"
          label="AVS Fee"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="rateSet.batchFee"
          label="Batch Fee"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="rateSet.debitAccessFee"
          label="Debit Access Fee"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          name="rateSet.additionalServicesFee"
          label="Additional Service Fees"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Select
          name="rateSet.pciFee"
          label="PCI Fee"
          errors={errors}
          control={control}
          displayValue={true}
          defaultValue={PCIFee.waived}
          onChangeSuccess={handlePCIFeeChange}
          compareFn={(a, b) => {
            return pciFeeTypes.indexOf(a[0]) - pciFeeTypes.indexOf(b[0]);
          }}
          options={PCIFeeTypes}
        />
      </Grid>
      {displayPCIFeeValue && (
        <Grid item xs={12} sm={3}>
          <TextField
            name="rateSet.pciFeeValue"
            label="PCI Fee Value"
            type="number"
            errors={errors}
            control={control}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={displayPCIFeeValue ? 2 : 3}>
        <TextField
          name="rateSet.earlyDeconversionFee"
          label="Early Deconversion Fee"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={displayPCIFeeValue ? 2 : 3}>
        <TextField
          name="rateSet.annualFee"
          label="Annual Fee"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={displayPCIFeeValue ? 2 : 3}>
        <TextField
          name="rateSet.regulatoryFee"
          label="Regulatory Fee"
          type="number"
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <ErrorBoundary>
        <ProgramEquipment
          application={currentApplication || application.data}
          equipmentData={currentApplication?.equipment || application.data?.equipment}
          control={control}
          errors={errors}
          equipmentList={equipment.data!}
          setValue={setValue}
          watch={watch}
          watchApplicationType={watchApplicationType}
          ratePrograms={ratePrograms}
        >
          <>
            {fields.map((eq, index) => (
              <ProgramEquipment
                key={index}
                application={currentApplication || application.data}
                equipmentData={eq as ApplicationEquipment}
                control={control}
                errors={errors}
                equipmentList={equipment.data!}
                setValue={setValue}
                watch={watch}
                watchApplicationType={watchApplicationType}
                ratePrograms={ratePrograms}
                isAdditionalEquipment
                itemIndex={index}
                equipmentRemove={() => remove(index)}
              />
            ))}
            <Grid item xs={12}>
              <Box pt={3} mb={2}>
                <Button
                  aria-label="Add Additional Equipment"
                  onClick={handleEquipmentAddDialogOpen}
                  startIcon={<AddIcon />}
                  size="large"
                  style={{
                    minHeight: '56px',
                  }}
                  color="primary"
                  variant="outlined"
                  fullWidth
                >
                  Add Additional Equipment
                </Button>
              </Box>
            </Grid>
          </>
        </ProgramEquipment>
      </ErrorBoundary>

      <Grid item xs={12}>
        <SectionTitle text="Upload Documents" />
      </Grid>
      <UploadDocuments errors={errors} isPortalUser={!isCrmUser} />
      <Grid item xs={12}>
        <SectionTitle text="Application Ownership" />
      </Grid>
      <Grid item xs={12}>
        <Select
          variant="outlined"
          name="agentId"
          label="Agent"
          displayEmpty
          errors={errors}
          control={control}
        >
          {generateAgentOptions()}
        </Select>
      </Grid>

      {descriptionDialogOpen && (
        <EquipmentDescriptionDialog
          dialogTitle="Add Additional Equipment"
          okTitle="Add"
          defaultValue=""
          open={descriptionDialogOpen}
          onClose={() => setNewItemDialogOpen(false)}
          onOk={handleEquipmentAdd}
        />
      )}
      {loadTemplateDialogOpen.isOpen && (
        <LoadTemplateDialog
          applicationTemplates={applicationTemplates.data ?? []}
          onClose={() => setLoadTemplateDialogOpen({isOpen: false})}
          onSubmit={onLoadTemplate}
        />
      )}
      {saveTemplateDialogOpen.isOpen && (
        <SaveTemplateDialog
          existingTemplate={loadedApplicationTemplate}
          onClose={() => setSaveTemplateDialogOpen({isOpen: false})}
          onSubmit={onSaveTemplate}
        />
      )}
    </Grid>
  );
};

export default ProgramPageForAgents;
