import {getEquipmentSchema, getWebProgramEquipmentValidationObject} from '@ozark/common';
import * as yup from 'yup';

export const getProgramEquipmentSchema = ({cashDiscountCap}: {cashDiscountCap?: number} = {}) => {
  const programEquipmentValidationCommonObject =
    getWebProgramEquipmentValidationObject(cashDiscountCap);
  return yup.object().shape({
    ...programEquipmentValidationCommonObject,
    equipment: getEquipmentSchema({cashDiscountCap, useCustomErrorMessage: true}).required(),
    equipmentAdditional: yup
      .array()
      .of(getEquipmentSchema({cashDiscountCap, useCustomErrorMessage: true}))
      .ensure(),
  });
};
